import { ApplicationController } from "./application_controller"

export default class extends ApplicationController {

  connect() {
    console.log('START AUTOMATIC SAVE RESPOSTA')
    this.automaticSave()
  }

  disconnect() {
    this.clearAutomaticSave()
  }

  automaticSave() {
    this.refreshTimer = setInterval(()=> {
      this.save()
    }, 300000)
  }

  clearAutomaticSave() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }
  }

  save(e) {
    let auto
    if (e) {
      e.preventDefault()
      auto = e.target.dataset.auto
    } else {
      auto = 'true'
    }

    const isUpdateOperation = this.data.get('update')
    const respostaId = this.data.get('resposta-id')
    const adendoId = this.data.get('adendo-id')
    const projectId = this.data.get('project-id')
    const SERVER = "https://cep.imt.usp.br"

    let data = $('.card-body > form').serializeArray()
    data.push({ name: "automatic_save", value: auto })

    if(isUpdateOperation == 'true') {
      // update
      $.ajax({
        url: `${SERVER}/projects/${projectId}/adendos/${adendoId}/respostas/${respostaId}.js`,
        type: 'PATCH',
        data: $.param(data)
      })
        .done(function (data) {
          console.log("success")
        })
        .fail(function (xhr, status, error) {
          console.log("error", xhr.responseText)
        })
        .always(function () {
          console.log("complete")
        })
    } else {
      // create
      $.ajax({
        url: `${SERVER}/projects/${projectId}/adendos/${adendoId}/respostas.js`,
        type: 'POST',
        data: $.param(data)
      })
        .done(function (data) {
          console.log("success")
        })
        .fail(function (xhr, status, error) {
          console.log("error", xhr.responseText)
        })
        .always(function () {
          console.log("complete")
        })
    }

     
  }

}